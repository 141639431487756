/* @tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: "Poppins" !important;
  }
} */


* {
  font-family: "Poppins";
  font-weight: 300;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 700px) {
  .cardHide {
    display: none;
  }
}

.modal-content {
  border-radius: 8px !important;
}
.modal.show .modal-dialog {
  border-radius: 8px !important;
}

