* {
  font-family: "Poppins" !important;
  font-size: 14px;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.category-card {
  background-color: rgba(0, 128, 55, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px auto;
  border-radius: 10px;
  /* padding:12px; */
  border: 1px solid #00803773;
}

.category-card img {
  border-radius: 10px;
}

.checkout-img {
  height: 20% !important;
  width: 20% !important;
}

.categoryText {
  font-size: 20px;
  font-family: Nunito, sans-serif;
  font-weight: bolder !important;
  color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #161e54, #161e54);
  padding: 0px 35px;
}

.items-card {
  /* background-color: rgba(0, 128, 55, 0.15); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  margin: 10px auto 20px auto;
  /* background-color: rgba(0, 128, 55, 0.039) */
}

.active-category {
  /* background-color: rgba(0, 128, 55, 0.15); */
  background-color: rgba(2, 73, 33, 0.6);
  /* height: 105px;
width: 205px; */
  box-shadow: 1px 1px 10px 2px lightgrey;
}

.item-details {
  flex-grow: 1;
  margin: 0px 30px;
  overflow-y: scroll;
}

.item-quantity {
  border: 1px solid #008037;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 4px;
  margin-right: 10px;
}

.items-container {
  /* border: 1px solid black; */
  /* padding: 0 30px; */
  position: relative;
  padding-bottom: 80px;
}

.cart-container {
  /* border: 1px solid black; */
  /* padding: 0 30px; */
  position: relative;
  flex: 2;
  height: 100vh;
}

.cat-text {
  font-weight: bolder !important;
  font-size: 14px;
}

.checkout-container {
  /* position: absolute;
    right: 50px; */
  /* width: 100%; */
  /* bottom: 50px; */
  display: flex;
  height: 60px;
  align-items: center;
  /* border: 1px solid rgba(0, 128, 55, 1); */
  justify-content: space-evenly;
  border-radius: 3px;
  position: fixed;
  bottom: 30px;
  right: 80px;
  /* width: 100%; */
  /* background-color:  rgba(196, 196, 196, 1); */
  box-shadow: 1px 1px 10px 7px lightgray;
  background-color: lightcyan;
  bottom: 0;
}

.checkout-container-text {
  font-size: 18px;
  padding: 20px 30px;
  font-weight: 400;
  font-family: Nunito, sans-serif;
}
.pointer {
  cursor: pointer;
}
.head,
.second-head {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 40px auto;
  font-weight: 600;
}

/* .second-head{
    height: 70px;
    width: 200px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    margin: 20px initial;
font-weight: 600;
padding: 15px;
} */

.inner-head-text {
  font-size: 21px;
  border: 1px solid rgba(0, 128, 55, 0.45);
  border-radius: 4px;
  width: fit-content;
  font-family: Nunito, sans-serif;
  padding: 5px 18px;
}

/* .offcanvas-header .btn-close{
    display: none;
} */

.offcanvas-bottom {
  height: 55vh !important;
}

input[aria-label="Please enter verification code. Digit 1"],
input[aria-label="Digit 2"],
input[aria-label="Digit 3"],
input[aria-label="Digit 4"] {
  height: 4rem;
  width: 4rem !important;
}

.common-text {
  font-weight: 700;
  font-size: 18px;
}

.selectItems {
  display: flex;
  flex: 2;
  flex-direction: column;
  padding: 0 25px;
  /* align-items: center; */
}

.containerScroll {
  overflow-y: scroll;
  border: 1px solid rgba(0, 128, 55, 0.45);
  border-radius: 10px;
}

.categoryBox {
  flex: 1.1;
}

.item-img2 {
  position: absolute;
  width: 90%;
  z-index: -1;
  opacity: 0.1;
}
.headerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-image: url("https://zymeal.com/storage/restaurant/cover/16311806716139d77f480e5.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top; */
  /* padding: 20px 15px; */
}
.headerBoxHeading {
  font-size: 20px;
  color: black;
  font-weight: bold;
  font-family: Nunito, sans-serif;
}
.headerBoxFlex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.headerBoxFlex2 {
  padding: 5px;
  display: flex;
  align-items: center;
  color: black;
  text-decoration: none;
}

.headerBoxFlex2:hover:not(.active) {
  color: #008037;
  cursor: pointer;
  transition: all 0.4s;
}

.rowData {
  display: flex;
  justify-content: space-around;
}
.pageCart {
  min-width: fit-content;
}

.offcanvas-backdrop {
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .rowData {
    flex-direction: column;
  }
  .category-card {
    flex-direction: column;
    margin: 0px 9px;
  }
  .categoryText {
    padding: 5px 15px;
  }
  .categoryBox {
    margin-top: 15px;
  }

  .cardContainer {
    display: flex;
    width: 90vw;
    flex-direction: row;
    overflow-x: scroll;
  }
  .head,
  .second-head {
    display: block;
    margin: 20px auto 20px auto;
  }
  .second-head {
    display: block;
    margin: 40px 0px 20px 0px;
  }
  .containerScroll {
    display: none;
  }
  #head2 {
    display: none;
  }
  .selectItems {
    margin-top: 25px;
    padding: 0;
  }
  .item-quantity {
    padding: 1px 6px;
  }
  .checkout-container {
    right: 0px;
    width: 100%;
  }
}
