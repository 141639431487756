// Here you can add other styles

.simple-select-error {
  color: #e55353 !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
}

* {
  font-family: 'Poppins';
  font-size: 14px;
}

.image-edit {
  color: white;
  font-size: 22px;
  bottom: -10px;
  right: -10px;
  position: absolute;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: green;
  padding: 4px;
  box-sizing: initial;
}

.breadcrumb-item a {
  color: gray !important;
  text-decoration: none !important;
}
