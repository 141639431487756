.bell-div {
  border: 3px solid black;
  display: inline-block;
  padding: 8px 13px;
  position: absolute;
  color: #9e9e9e;
  font-size: 22px;
  background-color: lightgray;
  right: 20px !important;
  cursor: pointer;
}

/* .bell{
    display:block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    margin:50px auto 0;
    color: #9e9e9e;
  } */

.bell.active {
  -webkit-animation: ring 4s 0.7s ease-in-out 2;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out 2;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out 2;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

@media only screen and (max-width: 800px) {
  .bell-div {
    left: auto !important ;
  }
}

.Menu_list li {
  padding-bottom: 2em;
  font-size: 14px;
  color: #696f76;
  cursor: pointer;
  font-weight: 500 !important;
}
.drawer_main .Menu_list li {
  font-weight: 500 !important;
  font-size: 14px;
}
.Menu_list a {
  text-decoration: none;
}
.Menu {
  text-align: center;
  font-weight: 1000 !important;
  letter-spacing: 1px;
}
.menu_item_comp {
  display: flex;
  border-bottom: 1px solid #ddd;
  /* border-width: 90%;  */
  justify-content: space-between;
  padding-bottom: 2em;
}
@media only screen and (max-width: 599px) {
  .Main_menu {
    display: none;
  }
  .toggle_btn {
    visibility: visible !important;
  }
}
.toggle_btn {
  position: fixed;
  bottom: 12px;
  right: 12px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: white;
  background-color: green;
  border: 2px solid white;
  visibility: hidden;
  box-shadow: 0 8px 12px rgb(0 0 0 / 20%);
}
.add_btn {
  width: 88px;
  /* position: absolute; */
  top: 78%;
  left: 18%;
  height: 36px;
  background: #fff;
  border: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
  border: 1px solid #e7eced;
  font-size: 14px;
  color: green;
  border-radius: 6px;
  font-weight: 500 !important;
}
.item_img {
  height: 100%;
  position: relative;
}
.active {
  color: green !important ;
  /* border-left: 2px solid #A6CE39; */
  position: relative;
  left: -5px;
}
.drawer_main .MuiPaper-root {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  max-height: 65vh;
  overflow: scroll;
}
.drawer_main li {
  font-size: 16px;
  font-weight: 600;
}
html {
  scroll-behavior: smooth !important;
}
.main_ui {
  font-family: "Inconsolata", monospace;
  font-family: "Montserrat", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
  font-family: "Titillium Web", sans-serif;
}

.checkout-container {
  color: white;
  background: green;
  z-index: 10;
}
.toggleUp {
  bottom: 65px !important;
}
